import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { LinkIcon } from "@heroicons/react/20/solid";
import { SiGoogleplay, SiReddit, SiX } from "@icons-pack/react-simple-icons";
import Layout from "components/Layout";
import ExternalLink from "components/links/ExternalLink";
import { PageProps } from "gatsby";
import { useIsDarkMode } from "hooks/use-media-query";
import AlternativeToSVG from "images/icons/alternativeto.inline.svg";
import HeartSVG from "images/icons/heart.inline.svg";
import React, { PropsWithChildren } from "react";
import Carousel from "react-bootstrap/Carousel";
import styled from "styled-components";
import {
    alternativeToLink,
    emailLink,
    siteURL,
    twitterLink,
} from "utils/promote";

const Share: React.FC<PageProps> = ({ path }) => {
    const layoutProps = {
        path,
        title: "Spread the word!",
        description:
            "All good things in life are meant to be shared. Ente is a good thing. ergo it must be shared 😎",
    };

    return (
        <Layout {...layoutProps}>
            <Body>
                <Section>
                    <SpreadTheWord />
                </Section>
            </Body>
        </Layout>
    );
};

export default Share;

const Body: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="max-w-lg mx-auto d-flex flex-column my-5">
            {children}
        </div>
    );
};

const Section: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <section className="bg-elevated rounded-3 px-3 py-3 px-md-5 text-break">
            {children}
        </section>
    );
};

const SpreadTheWord: React.FC = () => {
    return (
        <div className="d-md-flex flex-wrap gap-5">
            <ColumnItem>
                <SpreadColumnOne />
            </ColumnItem>
            <ColumnItem>
                <SpreadColumnTwo />
            </ColumnItem>
        </div>
    );
};

const ColumnItem = styled.div`
    flex: 1;
    margin: 1.5rem auto;
`;

const SpreadColumnOne: React.FC = () => {
    return (
        <div className="d-flex flex-column gap-3">
            <div>
                <h2 className="mt-0">Spread the word!</h2>
                <Description />
            </div>
            <SpreadActions />
        </div>
    );
};

const Description: React.FC = () => {
    return (
        <>
            <p>
                You found us, but there are many more people out there still
                searching. Help them out by letting them know of <b>ente</b> on
                your favorite social network.
            </p>
            <p>Good things are meant to be shared.</p>
        </>
    );
};

const SpreadActions: React.FC = () => {
    return (
        <div className="d-flex justify-content-between align-items-end pe-md-2">
            <TwitterLink />
            <EmailLink />
            <AlternativeToLink />
            <CopyURLLink />
        </div>
    );
};

const SpreadLink = styled(ExternalLink)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    text-decoration: none;
    font-variant: all-petite-caps;
    font-weight: 600;

    color: var(--color-muted);
    && svg {
        color: var(--color-primary-500);
    }

    &&:hover {
        color: var(--color-primary-700);
    }

    @media (prefers-color-scheme: dark) {
        &&:hover {
            color: var(--color-primary-400);
        }
    }
`;

const spreadLinkIconSize = 64;

const TwitterLink: React.FC = () => {
    return (
        <SpreadLink href={twitterLink} title="Tweet">
            <SiX size={spreadLinkIconSize} title="Tweet" />
            Tweet
        </SpreadLink>
    );
};

const EmailLink: React.FC = () => {
    return (
        <SpreadLink href={emailLink} title="Email">
            <EnvelopeIcon width={spreadLinkIconSize} />
            Mail
        </SpreadLink>
    );
};

const AlternativeToLink: React.FC = () => {
    return (
        <SpreadLink href={alternativeToLink} title="Rate us on AlternativeTo">
            <HeartSVG width={spreadLinkIconSize} height={spreadLinkIconSize} />
            Rate
        </SpreadLink>
    );
};

const CopyURLLink: React.FC = () => {
    const handleClick = () => {
        navigator.clipboard.writeText(siteURL);
    };

    return (
        <SpreadLink
            onClick={handleClick}
            title="Copy Ente's URL to your clipboard"
        >
            <LinkIcon width={spreadLinkIconSize} />
            Copy Link
        </SpreadLink>
    );
};

const SpreadColumnTwo: React.FC = () => {
    const isDarkMode = useIsDarkMode();
    const carouselColor = `carousel-muted ${isDarkMode ? "" : "carousel-dark"}`;

    return (
        <Carousel controls={false} className={`h-100 ${carouselColor}`}>
            <Carousel.Item>
                <Review1 />
            </Carousel.Item>
            <Carousel.Item>
                <Review2 />
            </Carousel.Item>
            <Carousel.Item>
                <Review3 />
            </Carousel.Item>
        </Carousel>
    );
};

const Review: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <ReviewCard className="d-flex text-muted spread-the-word-review-container">
            <div className="d-flex flex-column py-4 gap-4 w-100 align-items-center spread-the-word-review-container-overlay">
                {children}
            </div>
        </ReviewCard>
    );
};

const ReviewCard = styled.div`
    height: 297px;
`;

const Review1: React.FC = () => {
    // 105 is also the intrinsic size of the AlternativeTo SVG.

    return (
        <Review>
            <AlternativeToSVG
                className="opacity-25 d-block"
                width={105}
                title="Customer review of Ente on AlternativeTo.net (@laurensss)"
            />

            <p className="text-center w-75">
                The best Google Photos alternative that I could find that
                actually respects your privacy.{" "}
                <b>Absolutely love their product</b>.
            </p>
        </Review>
    );
};

const Review2: React.FC = () => {
    return (
        <Review>
            <SiReddit
                className="opacity-25 d-block"
                size={90}
                title="Customer comment on Reddit (@oscar_einstein)"
            />
            <p className="text-center w-75">
                ente.io is <b>quality</b>
            </p>
        </Review>
    );
};

const Review3: React.FC = () => {
    return (
        <Review>
            <SiGoogleplay
                className="opacity-25 d-block"
                size={90}
                title="Customer review on Google PlayStore"
            />
            <p className="text-center w-75">
                In my experience, Ente offers the best experience overall.{" "}
                <b>Encryption never looked so good!</b>
            </p>
        </Review>
    );
};
